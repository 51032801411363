var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',_vm._b({ref:"form",attrs:{"model":_vm.form}},'a-form-model',_vm.$formItemLayout,false),[_c('a-form-model-item',{attrs:{"label":"员工名称：","prop":"username","rules":{
      required: true,
      message: '请输入',
      trigger: 'blur',
    }}},[_c('a-input',{attrs:{"maxLength":25},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.username"}})],1),_c('a-form-model-item',{attrs:{"label":"手机号：","prop":"cellphone","rules":_vm.checkPhoneRule}},[_c('a-input',{attrs:{"maxLength":11},model:{value:(_vm.form.cellphone),callback:function ($$v) {_vm.$set(_vm.form, "cellphone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.cellphone"}})],1),_c('a-form-model-item',{attrs:{"label":"邮箱：","prop":"email","rules":_vm.checkEmail}},[_c('a-input',{attrs:{"maxLength":50},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})],1),_c('a-form-model-item',{attrs:{"label":"所属机构：","prop":"mechanismId","rules":{
      required: true,
      message: '请选择',
      trigger: 'blur',
    }}},[_c('a-cascader',{attrs:{"show-search":{ filter: _vm.filter },"options":_vm.mechanismOptions,"change-on-select":"","fieldNames":{ label: 'name', value: 'id', children: 'childrenList' },"placeholder":"请选择"},on:{"change":_vm.onChange},model:{value:(_vm.defaultMechanism),callback:function ($$v) {_vm.defaultMechanism=$$v},expression:"defaultMechanism"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"64px"},attrs:{"label":"职位：","prop":"postId","rules":{
      required: true,
      message: '请选择',
      trigger: 'blur',
    }}},[_c('a-select',{attrs:{"default-value":_vm.form.postId,"allowClear":"","placeholder":"请选择"},model:{value:(_vm.form.postId),callback:function ($$v) {_vm.$set(_vm.form, "postId", $$v)},expression:"form.postId"}},_vm._l((_vm.postOptions),function(postInfo){return _c('a-select-option',{key:postInfo.id,attrs:{"value":postInfo.id}},[_vm._v(_vm._s(postInfo.name))])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }