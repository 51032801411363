<template>
  <a-form-model ref="form" :model="form" v-bind="$formItemLayout">
    <a-form-model-item
      label="员工名称："
      prop="username"
      :rules="{
        required: true,
        message: '请输入',
        trigger: 'blur',
      }"
    >
      <a-input v-model.trim="form.username" :maxLength="25" />
    </a-form-model-item>
    <a-form-model-item label="手机号：" prop="cellphone" :rules="checkPhoneRule">
      <a-input v-model.trim="form.cellphone" :maxLength="11" />
    </a-form-model-item>
    <a-form-model-item label="邮箱：" prop="email" :rules="checkEmail">
      <a-input v-model.trim="form.email" :maxLength="50" />
    </a-form-model-item>
    <!-- <a-form-model-item
      label="状态："
      :rules="{
        required: true,
        message: '请选择',
        trigger: 'blur'
      }"
      prop="status"
    >
      <a-radio-group v-model="form.status">
        <a-radio v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
          {{ statusInfo.label }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item> -->
    <a-form-model-item
      label="所属机构："
      prop="mechanismId"
      :rules="{
        required: true,
        message: '请选择',
        trigger: 'blur',
      }"
    >
      <a-cascader
        :show-search="{ filter }"
        :options="mechanismOptions"
        change-on-select
        v-model="defaultMechanism"
        @change="onChange"
        :fieldNames="{ label: 'name', value: 'id', children: 'childrenList' }"
        placeholder="请选择"
      >
      </a-cascader>
    </a-form-model-item>
    <a-form-model-item
      label="职位："
      prop="postId"
      :rules="{
        required: true,
        message: '请选择',
        trigger: 'blur',
      }"
      style="margin-bottom: 64px"
    >
      <a-select :default-value="form.postId" v-model="form.postId" allowClear placeholder="请选择">
        <a-select-option v-for="postInfo in postOptions" :key="postInfo.id" :value="postInfo.id">{{
          postInfo.name
        }}</a-select-option>
      </a-select>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { cloneDeep } from 'lodash'
import { COMMON_STATUS } from '@/store/dictionary'
import * as postApi from '@/api/post'
import * as mechanismApi from '@/api/mechanism'
export default {
  data() {
    const MobileNumberValidator = (rule, value, callback) => {
      const idcardReg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (!idcardReg.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入正确的手机号码')
      }
      callback()
    }
    // 邮箱验证
    const EmailValidator = (rule, value, callback) => {
      const idcardReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      if (value && !idcardReg.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入正确格式的邮箱')
      }
      callback()
    }
    return {
      checkPhoneRule: [
        { validator: MobileNumberValidator, max: 11, trigger: ['change', 'blur'] },
        {
          required: true,
          message: '请输入',
          trigger: ['change', 'blur'],
        },
      ],
      checkEmail: [
        { validator: EmailValidator, max: 50, trigger: ['change', 'blur'] },
        {
          message: '请输入',
          trigger: ['change', 'blur'],
        },
      ],
      form: {
        username: '',
        cellphone: '',
        email: '',
        mechanismId: '',
        postId: '',
        status: 'AVAILABLE',
        password: '',
      },
      statusOptions: COMMON_STATUS,
      postOptions: [],
      mechanismOptions: [],
      defaultMechanism: [],
    }
  },
  mounted() {
    postApi.postOptions().then((res) => {
      this.postOptions = res.data
    })
    mechanismApi.mechanismOptions().then((res) => {
      this.mechanismOptions = res.data
    })
  },
  methods: {
    initData(form, defaultMechanism) {
      this.form = Object.assign({}, this.form, form)
      if (defaultMechanism) {
        this.defaultMechanism = defaultMechanism
      }
    },
    validate() {
      const {
        $refs: { form },
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    formatForm() {
      try {
        const ret = cloneDeep(this.form)
        return { ...ret }
      } catch {}
    },
    onChange(record) {
      this.defaultMechanism = record
      this.$set(this.form, 'mechanismId', record[record.length - 1])
    },
  },
}
</script>

<style lang="less" scoped></style>
